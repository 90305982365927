.testimonial-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.testimonial-heading {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.testimonial-item {
  background-color: #f7b538;
  width: 100%;
  height: 10rem;
  padding: 1rem;
  box-sizing: border-box;
  color: rgba(255, 255, 255, 1);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.second-item {
  display: none;
}

.testimonial-stars,
.testimonial-quote,
.testimonial-caption {
  margin: 0 0 0.5em 0;
}

.testimonial-quote {
  font-size: 0.85rem;
}

.testimonial-caption {
  font-size: 0.8rem;
  font-style: italic;
  text-align: end;
}

@media screen and (min-width: 600px) {
  .testimonial-item {
    width: 49%;
    padding: 2rem;
  }

  .second-item {
    display: flex;
  }
}
