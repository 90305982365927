.pop-up {
  background-color: rgba(75, 75, 75, 1);
  padding: 2rem 1.5rem 2.5rem;
  width: 80%;
  height: auto;
  color: rgba(255, 255, 255, 1);
  position: relative;
  max-height: 90%;
  font-size: 0.9em;
  margin: 2em auto;
  max-width: 20em;
}

.pop-up form {
  display: flex;
  flex-direction: column;
}
.pop-up form input[type="text"],
textarea {
  width: 100%;
  margin: 0.5em 0;
  padding: 1em;
  border: none;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 0.8em;
  border: none;
  outline: none;
  background-color: rgba(255, 255, 255, 0.95);
}

.pop-up h2 {
  padding: 0;
  margin: 0 0 1em;
}

::placeholder {
  color: rgba(0, 0, 0, 0.7);
}

::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.7);
}

.pop-up form input[type="submit"] {
  padding: 0.7em 1.5em;
  text-align: center;
  border: none;
  margin: 0.5em 0;
  font-size: 0.9em;
  width: auto;
  margin: 2em 0 0 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.form-close {
  position: absolute;
  top: 1em;
  right: 1em;
  color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
}

@media screen and (max-height: 450px) {
  .show {
    align-items: flex-start;
    overflow: auto;
  }
  .pop-up {
    width: 50%;
    height: auto;
    max-height: none;
  }
}

@media screen and (min-width: 600px) and (min-height: 1000px) {
  .pop-up {
    width: 50%;
    font-size: 1.2em;
  }
}

@media screen and (min-width: 1000px) {
  .pop-up {
    width: 25%;
    font-size: 1em;
  }
}
