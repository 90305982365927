.show-case {
  width: 90%;
  height: 85%;
  background-color: white;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.9);
  position: relative;
  padding: 0 0 3em 0;
  margin: auto 0;
}

.case-close {
  position: absolute;
  bottom: 1em;
  right: 1em;
  z-index: 5;
  cursor: pointer;
}

.inner-show-case {
  width: 100%;
  height: 100%;
  display: block;
  overflow-y: scroll;
}

.inner-show-case .case-image,
.case-description {
  width: 100%;
  height: auto;
}

.inner-show-case {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.inner-show-case::-webkit-scrollbar {
  display: none;
}

.inner-show-case .case-image img {
  width: 100%;
}

.inner-show-case h2,
.inner-show-case p {
  padding: 0.5em 1em 0;
}

@media screen and (min-width: 600px) and (min-height: 1000px) {
  .show-case {
    width: 70%;
    height: 80%;
    font-size: 1.5em;
  }

  .inner-show-case .case-image,
  .case-description {
    width: 100%;
    height: auto;
  }

  .inner-show-case .case-image {
    margin: 0;
  }

  .inner-show-case .case-image img {
    width: 100%;
    margin: 0;
  }
}

@media screen and (min-width: 600px) {
  .show-case {
    width: 70%;
    height: 80%;
    font-size: 1em;
  }
}

@media screen and (max-height: 480px) and (max-width: 1000px) {
  .show-case {
    font-size: 1em;
    width: 70%;
  }
}

@media screen and (min-width: 1000px) {
  .show-case {
    height: 80%;
    max-width: 35%;
    font-size: 1em;
  }

  .inner-show-case .case-image img {
    width: 100%;
    height: auto;
  }
}
