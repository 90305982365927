.team-item {
  --width: calc(100% / 3 - 0.5em);
  width: calc(100% / 3 - 0.5em);
  height: calc(var(--width) * 1.2);
}

.team-image-wrapper {
  width: 100%;
  height: 80%;
  overflow: hidden;
}

.team-image-wrapper img {
  width: 100%;
  height: auto;
}
