.footer-overlay img {
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.25rem 0 0;
  filter: brightness(20%);
}

.footer-overlay img:hover {
  filter: brightness(90%);
}
