.caseStudiesHeading {
  font-size: 0.9em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.cases-subheading {
  display: none;
  margin: 0;
}

.section-wrapper {
  margin: 0.5rem auto;
  display: grid;
  gap: 0.5em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  height: 70vh;
  min-height: 32rem;
}

.inner-case {
  overflow: hidden;
  cursor: pointer;
}

.inner-case img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  max-height: 100%;
  transition: 400ms ease-in-out;
}

.inner-case img:hover {
  scale: 1.1;
}

.inner-case#id1,
.inner-case#id2 {
  width: 100%;
}

.inner-case#id3,
.inner-case#id4 {
  width: 100%;
}

.inner-case#id1 {
  grid-row-start: 1;
  grid-row-end: span 5;
  grid-column-start: 1;
  grid-column-end: span 1;
  background-color: rgb(245, 245, 245, 1);
}

.inner-case#id2 {
  grid-row-start: 6;
  grid-row-end: span 5;
  grid-column-start: 1;
  grid-column-end: span 1;
  background-color: #cad2c5;
}

.inner-case#id3 {
  grid-row-start: 1;
  grid-row-end: span 9;
  grid-column-start: 2;
  grid-column-end: span 1;
  background-color: #cad2c5;
}

.inner-case#id4 {
  grid-row-start: 10;
  grid-row-end: span 1;
  grid-column-start: 2;
  grid-column-end: span 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-height: 1.2em;
}

@media screen and (min-width: 600px) {
  .caseStudiesHeading {
    justify-content: space-between;
  }

  .cases-subheading {
    display: inline;
    margin: 0;
  }
}

@media screen and (min-width: 1000px) {
  .section-wrapper {
    height: 85vh;
  }
}
