body,
html {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "poppins", "fallback for poppins";
}

@font-face {
  font-family: "fallback for poppins";
  src: local("Verdana");
  size-adjust: 100%;
  ascent-override: 105%;
  descent-override: 40%;
  line-gap-override: 5%;
}

@media screen and (min-width: 1500px) {
  html {
    font-size: 18px;
  }
}