.welcome-page {
  width: 100%;
  height: auto;
  background-color: rgb(154, 8, 8);
  position: relative;
  overflow: hidden;
  margin: 0 0 2em 0;
}

.hero-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
}

.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.welcome-overlay {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  z-index: 1;
  position: relative;
}

.body-section {
  width: 90%;
  padding: 1em 0 2em;
  margin: 0 auto;
}

.body-section h3 {
  font-size: 1.3rem;
  margin: 0;
}

.section-wrapper-nogrid {
  margin: 0.5rem auto;
  display: flex;
  justify-content: space-between;
}

.violet-btn {
  background-color: #000000;
  color: white;
  cursor: pointer;
  padding: 0.5em 0;
}

.violet-btn:active {
  transform: translateY(2px);
}

.caption h4 {
  font-size: 1rem;
  margin: 0 0 0.25rem 0;
}

.caption p {
  font-size: 0.85em;
  margin: 0 0 1rem 0;
}

.hidden {
  display: none;
}

.show {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.85);
}

.footer-page {
  width: 100%;
  height: auto;
  color: #2f4858;
  background-color: #f5f8ff;
  margin: 10% 0 0 0;
}

.footer-overlay {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;
  font-size: 0.75rem;
}

.footer-address {
  width: 60%;
}

.arrows {
  width: auto;
  display: flex;
  justify-content: end;
  align-items: center;
}

.left-arrow,
.right-arrow {
  width: 2em;
  height: 2em;
  margin: 0 0 0 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.left-arrow img,
.right-arrow img {
  width: 1em;
  height: 1em;
}

.left-arrow:hover,
.right-arrow:hover {
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

.gallery-arrows {
  position: absolute;
  top: calc(50% - 1em);
  z-index: 4;
  width: 90%;
  justify-content: space-between;
  align-items: center;
}

.gallery-arrows .left-arrow,
.gallery-arrows .right-arrow {
  margin: 0;
  background-color: rgba(81, 81, 81, 0.2);
  border-radius: 50%;
  scale: 1.5;
}

.gallery-arrows .left-arrow:hover,
.gallery-arrows .right-arrow:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.close-image {
  display: none;
}

@media screen and (min-width: 600px) and (min-height: 1000px) {
  .body-section {
    width: 90%;
  }

  .welcome-overlay {
    height: 40rem;
  }

  .gallery-arrows {
    width: 90%;
    justify-content: space-between;
  }

  .gallery-arrows .left-arrow,
  .gallery-arrows .right-arrow {
    scale: 2.5;
  }
}

@media screen and (min-width: 600px) {
  .gallery-arrows .left-arrow,
  .gallery-arrows .right-arrow {
    scale: 2;
  }

  .welcome-overlay {
    height: 40rem;
  }

}

@media screen and (max-height: 480px) and (max-width: 1000px) {
  .gallery-arrows .left-arrow,
  .gallery-arrows .right-arrow {
    scale: 2;
  }
  
  .welcome-overlay {
    height: 40rem;
  }
}

@media screen and (min-width: 1000px) {
  .body-section {
    width: 60%;
  }

  .welcome-overlay {
    height: 40rem;
  }

  .gallery-arrows {
    width: 70%;
  }

  .gallery-arrows .left-arrow,
  .gallery-arrows .right-arrow {
    scale: 2;
  }
}
